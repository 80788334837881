/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { changeLanguage } from '../../i18n';
import {
  LANGUAGES_BY_COUNTRY,
  ALL_LANGUAGES,
  ENGLISH,
  ITALIAN_SWISS,
  FRENCH_SWISS,
} from '../../helpers/SupportedLanguages';

import Text from '../forms/inputs/Text';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'end',
    // paddingBottom: theme.spacing(1),
  },
  baseRoot: {
    // marginTop: theme.spacing(0),
    // marginRight: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  // formControl: {
  //   width: 90,
  // },
  root: {
    color: 'tint-stack-neutral',
    fontSize: '1rem',
  },
  underline: {
    border: 'none',
    transition: 'none',
    '&:before': {
      borderBottom: 'none',
      transition: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
      transition: 'none',
    },
    '&:focused': {
      border: 'none',
      transition: 'none',
    },
  },
  icon: {
    color: 'tint-stack-neutral',
    fontSize: '1rem',
  },
}));

export default function SelectIdiom({
  dispatchLocale,
  locale,
  notificationLanguage,
  dispatchNotificationLanguage,
  user,
  getPathLocation,
}) {
  const classes = useStyles();
  const userCountry = user.country ? user.country.alpha2 : '';
  const languagesByCountry = LANGUAGES_BY_COUNTRY[userCountry] || [];
  // eslint-disable-next-line no-undef
  const isProd = process.env.REACT_APP_ENV === 'production';
  const currentLanguage = ALL_LANGUAGES.find(l => l.code === locale);


  const filteredLanguages = isProd
    ? languagesByCountry.filter((language) => language.displayInProd === true)
    : languagesByCountry.filter((language) => language.displayInTMP === true);

  const filteredLanguagesLoginPage = isProd
    ? ALL_LANGUAGES.filter((name) => name.displayInProd === true)
    : ALL_LANGUAGES.filter((name) => name.displayInTMP === true);

  function setSelectedLanguage(lang) {
    changeLanguage(lang.code);
    dispatchLocale(lang.code);
    dispatchNotificationLanguage(lang);
  }

  useEffect(async () => {
    if (userCountry && languagesByCountry.length) {
      //! after Login
      if (filteredLanguages.some((e) => e.code === locale)) {
        var varLangFound = filteredLanguages.find((e) => e.code === locale);
        changeLanguage(await varLangFound.code);
        dispatchLocale(await varLangFound.code);
        dispatchNotificationLanguage(await varLangFound);
      } else {
        //language is disabled that was previous selected, select the first language available
        changeLanguage(await filteredLanguages[0].code);
        dispatchLocale(await filteredLanguages[0].code);
        dispatchNotificationLanguage(await filteredLanguages[0]);
      }
    } else {
      //! before login
    }
  }, [locale, userCountry, filteredLanguages]);

  useEffect(async () => {
    if (getPathLocation) {
      //! This will run only for Admin Dashboard, to set english in case of other language is set in PP client
      changeLanguage(await ALL_LANGUAGES[0].code);
    }
    document.body.dir = currentLanguage?.dir || 'ltr';
  }, [currentLanguage]);

  return (
    <Grid item className={classes.wrapper}>
      <ValidatorForm className={classes.formControl} onSubmit={() => {}}>
        <Text
          select
          classes={{
            root: classes.baseRoot,
          }}
          InputProps={{
            classes: {
              root: classes.root,
              underline: classes.underline,
            },
          }}
          SelectProps={{
            IconComponent: KeyboardArrowDownIcon,
            classes: {
              icon: classes.icon,
            },
          }}
          value={locale}
          onChange={(event) => dispatchLocale(event)}
        >
          {userCountry && languagesByCountry.length
            ? filteredLanguages.map((lang, i) => (
                <div
                  key={i}
                  value={lang.code}
                  className='px-3 py-1 cursor-pointer hover:bg-light-gray'
                  onClick={() => setSelectedLanguage(lang)}
                >
                  {lang.name}
                </div>
              ))
            : filteredLanguagesLoginPage.map((lang, i) => (
                <div
                  key={i}
                  value={lang.code}
                  className='px-3 py-1 cursor-pointer hover:bg-light-gray'
                  onClick={() => setSelectedLanguage(lang)}
                >
                  {lang.name}
                </div>
              ))}
        </Text>
      </ValidatorForm>
    </Grid>
  );
}

SelectIdiom.propTypes = {
  dispatchLocale: PropTypes.func,
  dispatchNotificationLanguage: PropTypes.func,
  locale: PropTypes.string,
  notificationLanguage: PropTypes.object,
  user: PropTypes.object,
  getPathLocation: PropTypes.any,
};

SelectIdiom.defaultProps = {
  user: {},
};
