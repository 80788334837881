const tagExclusion = {};

const tagSystem = {
  'cvent.steps.sessionQuestion': {
    'cvent.steps.session3': {
      key: 'Product',
      value: 'Session 3',
    },
    'cvent.steps.session4': {
      key: 'Product',
      value: 'Session 4',
    },
  },
  'cvent.steps.session1Question': {
    'cvent.steps.session1': {
      key: 'Product',
      value: 'Session 1',
    },
  },
  'cvent.steps.cvent_training_question_title': {
    'cvent.steps.cvent_carelink_answer': {
      key: 'Training',
      value: 'Carelink',
    },
    'cvent.steps.cvent_sensor_answer': {
      key: 'Training',
      value: 'Sensor',
    },
  },
};

export function flow(summary) {
  const finalTags = [];
  const filtered = [];

  for (const userTags of summary) {
    const stepNamesToRemove = tagExclusion[userTags.option.title] || [];

    if (stepNamesToRemove) {
      for (const stepName of stepNamesToRemove) {
        filtered.push(stepName);
      }
    }
  }
  const chosenTags = summary
    .filter((step) => {
      for (const exclusion of filtered) {
        if (exclusion === step.stepName) {
          return false;
        }
      }
      return true;
    })
    .map((choice) => {
      if (tagSystem[choice.stepName]) {
        return tagSystem[choice.stepName][choice.option.title];
      }
      return undefined;
    });
  for (const choice of chosenTags) {
    if (choice !== undefined) {
      finalTags.push(choice);
    }
  }

  return finalTags;
}
