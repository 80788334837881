const tagSystem = {
  'cvent.steps.diabetes_center': {
    'cvent.steps.cvent_first_center': {
      key: 'Region',
      value: 'Attika Region',
    },
    'cvent.steps.cvent_second_center': {
      key: 'Region',
      value: 'Central/North Greece Region',
    },
    'cvent.steps.cvent_third_center': {
      key: 'Region',
      value: 'Other Region',
    },
  },
  'cvent.steps.cvent_already_have_experience_with_med_title': {
    'cvent.steps.cvent_already_have_experience_with_med_yes': {
      key: 'Pump Experience',
      value: 'MDT Experience',
    },
    'cvent.steps.cvent_already_have_experience_with_med_no': {
      key: 'Pump Experience',
      value: 'No Experience',
    },
  },
  'cvent.steps.cvent_first_training_with_exp_title': {
    'cvent.steps.cvent_first_training_with_exp_yes': {
      key: 'Session with Exp',
      value: 'Second',
    },
    'cvent.steps.cvent_first_training_with_exp_no': {
      key: 'Session with Exp',
      value: 'First',
    },
  },
  'cvent.steps.cvent_first_training_title': {
    'cvent.steps.cvent_first_training_yes': {
      key: 'Session without Exp',
      value: 'Second',
    },
    'cvent.steps.cvent_first_training_no': {
      key: 'Session without Exp',
      value: 'First',
    },
  },
  'cvent.steps.cvent_second_training_title': {
    'cvent.steps.cvent_second_training_yes': {
      key: 'Session',
      value: 'Third',
    },
    'cvent.steps.cvent_second_training_no': {
      key: 'Session',
      value: 'Second',
    },
  },
};

const tagsExclusinCases = {
  tagMinimed: [
    {
      key: 'Training',
      value: 'Ρύθμιση του Συστήματος MiniMedTM 780G - Central/North',
    },
  ],
  tagMinimedOther: [
    {
      key: 'Training',
      value: 'Ρύθμιση του Συστήματος MiniMedTM 780G - Other',
    },
  ],
  tagCGMOther: [
    {
      key: 'Training',
      value: 'CGM και Λογισμικό CareLink - Other',
    },
  ],
  tagCGM: [
    {
      key: 'Training',
      value: 'CGM και Λογισμικό CareLink - Central/North',
    },
  ],
  tagMinimedNoExpOther: [
    {
      key: 'Training',
      value: 'Γνωριμία με το Σύστημα MiniMedTM 780G - Other',
    },
  ],
  tagMinimedNoExp: [
    {
      key: 'Training',
      value: 'Γνωριμία με το Σύστημα MiniMedTM 780G - Central/North',
    },
  ],
  tagSecondTrainingNoExpOther: [
    {
      key: 'Training',
      value: 'Επανάληψη - Σετ έγχυσης και Δεξαμενή - Other',
    },
  ],
  tagSecondTrainingNoExp: [
    {
      key: 'Training',
      value: 'Επανάληψη - Σετ έγχυσης και Δεξαμενή - Central/North',
    },
  ],
  tagThirdTrainingNoExp: [
    {
      key: 'Training',
      value: 'CGM και Λογισμικό CareLink - Central/North',
    },
  ],
  tagThirdTrainingNoExpOther: [
    {
      key: 'Training',
      value: 'CGM και Λογισμικό CareLink - Other',
    },
  ],
};
export function flow(summary) {
  const finalTags = [];
  const finalTagsEdit = [];

  for (const choice of summary) {
    if (tagSystem[choice.stepName]) {
      finalTags.push(tagSystem[choice.stepName][choice.option.title]);
    }
  }
  if (
    finalTags.some(
      (e) => e.key === 'Region' && e.value != 'Central/North Greece Region'
    )
  ) {
    if (
      finalTags.some(
        (e) => e.key === 'Pump Experience' && e.value === 'MDT Experience'
      ) &&
      finalTags.some((e) => e.key === 'Session with Exp' && e.value === 'First')
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagMinimedOther);
    } else if (
      finalTags.some(
        (e) => e.key === 'Pump Experience' && e.value === 'MDT Experience'
      ) &&
      finalTags.some(
        (e) => e.key === 'Session with Exp' && e.value === 'Second'
      )
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagCGMOther);
    } else if (
      finalTags.some(
        (e) => e.key === 'Pump Experience' && e.value != 'MDT Experience'
      )
    ) {
      if (
        finalTags.some(
          (e) => e.key === 'Session without Exp' && e.value === 'First'
        )
      ) {
        finalTagsEdit.push(...tagsExclusinCases.tagMinimedNoExpOther);
      } else if (
        finalTags.some(
          (e) => e.key === 'Session without Exp' && e.value === 'Second'
        ) &&
        finalTags.some((e) => e.key === 'Session' && e.value != 'Third')
      ) {
        finalTagsEdit.push(...tagsExclusinCases.tagSecondTrainingNoExpOther);
      } else if (
        finalTags.some((e) => e.key === 'Session' && e.value === 'Third')
      ) {
        finalTagsEdit.push(...tagsExclusinCases.tagThirdTrainingNoExpOther);
      }
    }
  } else if (
    finalTags.some(
      (e) => e.key === 'Region' && e.value === 'Central/North Greece Region'
    )
  ) {
    if (
      finalTags.some(
        (e) => e.key === 'Pump Experience' && e.value === 'MDT Experience'
      ) &&
      finalTags.some((e) => e.key === 'Session with Exp' && e.value === 'First')
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagMinimed);
    } else if (
      finalTags.some(
        (e) => e.key === 'Pump Experience' && e.value === 'MDT Experience'
      ) &&
      finalTags.some(
        (e) => e.key === 'Session with Exp' && e.value === 'Second'
      )
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagCGM);
    } else if (
      finalTags.some(
        (e) => e.key === 'Pump Experience' && e.value != 'MDT Experience'
      )
    ) {
      if (
        finalTags.some(
          (e) => e.key === 'Session without Exp' && e.value === 'First'
        )
      ) {
        finalTagsEdit.push(...tagsExclusinCases.tagMinimedNoExp);
      } else if (
        finalTags.some(
          (e) => e.key === 'Session without Exp' && e.value === 'Second'
        ) &&
        finalTags.some((e) => e.key === 'Session' && e.value != 'Third')
      ) {
        finalTagsEdit.push(...tagsExclusinCases.tagSecondTrainingNoExp);
      } else if (
        finalTags.some((e) => e.key === 'Session' && e.value === 'Third')
      ) {
        finalTagsEdit.push(...tagsExclusinCases.tagThirdTrainingNoExp);
      }
    }
  }
  return finalTagsEdit;
}
