export default [
  {
    stepNumber: '1',
    stepName: 'Boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.de_first_step.title',
        label: 'cvent.cards.de_first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.de_first_step.description',
        // alert: 'cvent.cards.de_first_step.alert',
        disabled: false,
      },
      {
        from: '1',
        to: '19',
        title: 'cvent.cards.fifth_step.title',
        label: 'cvent.cards.fifth_step.button',
        image: '/workflow/workshop.jpg',
        device: '',
        description: 'cvent.cards.fifth_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '16',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/guardian.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.second_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '3.1',
        title: 'cvent.cards.sixth_step.title',
        label: 'cvent.cards.sixth_step.button',
        image: '/workflow/smart-mdi.jpg',
        device: 'workflow/mdi-system.png',
        description: 'cvent.cards.sixth_step.description',
        disabled: false,
      },
      // {
      //   from: '1',
      //   to: '17',
      //   title: 'cvent.cards.third_step.title',
      //   additional_information: 'cvent.cards.third_step.additional_information',
      //   label: 'cvent.cards.third_step.button',
      //   image: '/workflow/for-kids.jpg',
      //   description: 'cvent.cards.third_step.description',
      //   disabled: false,
      // },
      {
        from: '1',
        to: '18',
        title: 'cvent.cards.fourth_step.title',
        label: 'cvent.cards.fourth_step.button',
        image: '/workflow/special-training.jpg',
        description: 'cvent.cards.fourth_step.description',
        disabled: false,
      },
      // {
      //   from: '1',
      //   to: 'Summary',
      //   title: 'cvent.cards.fota_step.title',
      //   label: 'cvent.cards.fota_step.button',
      //   image: '/workflow/workshop.jpg',
      //   device: '',
      //   description: 'cvent.cards.fota_step.description',
      //   disabled: false,
      // },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '6b',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '3.1',
    centered: true,
    stepName: 'cvent.steps.cvent_select_entry_level_info',
    stepDescription: 'cvent.steps.cvent_select_entry_level_info',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '3.1',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Therapy',
            value: 'SmartMDI',
          },
        ],
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  // {
  //   stepNumber: '3',
  //   centered: true,
  //   stepName: 'cvent.steps.cvent_select_entry_level_title',
  //   stepDescription: '',
  //   stepDisclaimer: '',
  //   stepCategory: 'cvent.steps.cvent_entry_level',
  //   componentType: 'RadioSelection',
  //   stepImages: [],
  //   options: [
  //     {
  //       from: '3',
  //       to: '5',
  //       title: 'cvent.steps.cvent_select_entry_level_first_pump',
  //       label: '',
  //     },
  //     {
  //       from: '3',
  //       to: '5',
  //       title: 'cvent.steps.cvent_select_entry_level_already_have_pump',
  //       label: '',
  //     },
  //   ],
  // },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.cvent_will_be_trained_on_pump_title',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    options: [
      // {
      //   'from': '5',
      //   'to': '6a',
      //   'title': 'cvent.steps.cvent_M670G',
      //   'label': ''
      // },
      {
        from: '5',
        to: '6b',
        title: 'cvent.steps.cvent_M780G',
      },
    ],
  },
  {
    stepNumber: '6a',
    centered: true,
    stepName: 'cvent.steps.cvent_will_be_trained_on_system_title_670g',
    stepDescription: '',
    stepDisclaimer:
      'cvent.steps.cvent_technical_instructions_acknowledgement_title',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'RadioSelection',
    options: [
      {
        from: '6a',
        to: 'Summary',
        title: 'cvent.steps.cvent_will_be_trained_on_system_pump_only_670g',
        label: '',
      },
      {
        from: '6a',
        to: '8a',
        title: 'cvent.steps.cvent_will_be_trained_on_system_cgm_for_pump_670g',
        label: '',
      },
      {
        from: '6a',
        to: 'Summary',
        title: 'cvent.steps.cvent_will_be_trained_on_system_pump_and_cgm_670g',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6b',
    centered: true,
    stepName: 'cvent.steps.cvent_will_be_trained_on_system_title_770g',
    stepDescription: '',
    stepDisclaimer:
      'cvent.steps.cvent_technical_instructions_acknowledgement_title',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'RadioSelection',
    options: [
      {
        from: '6b',
        to: 'Summary',
        title: 'cvent.steps.cvent_will_be_trained_on_system_pump_only_770g',
        label: '',
      },
      {
        from: '6b',
        to: 'Summary',
        title: 'cvent.steps.cvent_will_be_trained_on_system_cgm_for_pump_770g',
        label: '',
      },
      {
        from: '6b',
        to: 'Summary',
        title: 'cvent.steps.cvent_will_be_trained_on_smart_guard_automodus',
        label: '',
      },
      // {
      //   'from': '6b',
      //   'to': 'Summary',
      //   'title': 'cvent.steps.cvent_will_be_trained_on_system_pump_and_cgm_770g',
      //   'label': ''
      // }
    ],
  },
  {
    stepNumber: '8a',
    centered: true,
    stepName: 'cvent.steps.cvent_trained_on_existing_pump_title',
    stepDescription: 'cvent.steps.cvent_trained_on_existing_pump_title',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8a',
        to: 'Summary',
        title: 'cvent.steps.cvent_trained_on_existing_pump_yes',
        label: '',
      },
      {
        from: '8a',
        to: '9a',
        title: 'cvent.steps.cvent_trained_on_existing_pump_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '9a',
    centered: true,
    stepName: 'cvent.steps.cvent_technical_instructions_of_pump_first',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '9a',
        to: '6a',
        title: 'cvent.steps.cvent_continue',
        label: '',
      },
    ],
  },
  {
    stepNumber: '8b',
    centered: true,
    stepName: 'cvent.steps.cvent_trained_on_existing_pump_title',
    stepDescription: 'cvent.steps.cvent_trained_on_existing_pump_title',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8b',
        to: 'Summary',
        title: 'cvent.steps.cvent_trained_on_existing_pump_yes',
        label: '',
      },
      {
        from: '8b',
        to: '9b',
        title: 'cvent.steps.cvent_trained_on_existing_pump_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '9b',
    centered: true,
    stepName: 'cvent.steps.cvent_technical_instructions_of_pump_first',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '9b',
        to: '6b',
        title: 'cvent.steps.cvent_continue',
        label: '',
      },
    ],
  },
  {
    stepNumber: '16',
    stepName: 'Form 1st Box',
    stepDescription: 'Form Technical instruction',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    formImages: {
      fill: '/media/guardian_form_image.jpg',
      summary: '/media/guardian_confirmation_image.jpg',
    },
    componentType: 'FirstForm',
    options: [
      {
        from: '1',
        to: '1',
        title: 'OK',
        label: '',
      },
    ],
  },
  {
    stepNumber: '17',
    stepName: 'Form 2nd Box',
    stepDescription: 'Form Technical instruction for Kids',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    formImages: {
      fill: '/media/kids_form_image.jpg',
      summary: '/media/kids_confirmation_image.jpg',
    },
    componentType: 'SecondForm',
    options: [
      {
        from: '1',
        to: '1',
        title: 'OK',
        label: '',
      },
    ],
  },
  {
    stepNumber: '18',
    stepName: 'Form 3nd Box',
    stepDescription: 'Form Special Technical instructions',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    formImages: {
      fill: '/media/special_training_form_image.jpg',
      summary: '/media/special_training.jpg',
    },
    componentType: 'ThirdForm',
    options: [
      {
        from: '1',
        to: '1',
        title: 'OK',
        label: '',
      },
    ],
  },
  {
    stepNumber: '19',
    stepName: 'cvent.technical.workshop.title',
    stepDescription: 'cvent.technical.workshop.description',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    formImages: {
      fill: '/media/special_training_form_image.jpg',
      summary: '/media/special_training.jpg',
    },
    componentType: 'SingleInputSelection',
    options: [
      {
        from: '1',
        to: '1',
        title: 'OK',
        label: '',
      },
    ],
  },
];
