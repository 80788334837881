/* eslint-disable indent */
/* eslint-disable */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../button/Button';

const mapStateToProps = (state) => {
  return {
    last_trainrg: state.tagsReducer.last_trainrg,
    pathway: state.pathwayReducer.pathway,
  };
};

export function PatientPortalCard({ box, handleClick, style }) {
  const { t } = useTranslation();

  function storeBoxReduxStore(e) {
    if (box?.disabled == true) {
      //nothing
    } else {
      e.preventDefault();
      handleClick(e);
    }
  }

  function boxCSS() {
    var backgroundCSS;
    if (box?.blank == true) {
      backgroundCSS = 'bg-transparent p-5';
    } else {
      backgroundCSS = 'bg-navy-blue p-2';
    }
    return backgroundCSS;
  }
  return (
    <div className='grid grid-cols-2 '>
      <img
        style={{ objectFit: 'cover', width: 'auto', height: '100%' }}
        src={box.image}
        className='rounded-l-lg'
      />

      <div
        className={boxCSS() + ' flex flex-col flex-1 px-3 py-5 rounded-r-lg '}
      >
        <div className='flex-1'>
          <h2 className='leading-snug '>
            {document.body.dir !== 'rtl' && (
              <span className='font-bold leading-tight break-words text-light-blue'>
                {t(box.title)}
              </span>
            )}
            {document.body.dir === 'rtl' && (
              <span className='font-bold leading-tight text-right break-words text-light-blue'>
                {t(box.title)}
              </span>
            )}

            <span
              className={`leading-none font-bold uppercase ${
                box.additional_information ? 'block' : 'hidden'
              }`}
            >
              {t(box.additional_information)}
            </span>
          </h2>
          <p className='mt-2 '>
            {document.body.dir !== 'rtl' && (
              <span className='h-32 pb-2 overflow-auto text-xs leading-tight text-white whitespace-pre-wrap lg:pb-0 scrollbar handle hover'>
                {t(box.description)}
              </span>
            )}
            {document.body.dir === 'rtl' && (
              <span className='h-32 pb-2 overflow-auto text-xs leading-tight text-right text-white whitespace-pre-wrap lg:pb-0 scrollbar handle hover'>
                {t(box.description)}
              </span>
            )}
          </p>
        </div>
        {box.label && (
          <div className='flex justify-end mt-2 md:pt-0'>
            <Button
              className='px-4 rounded-full text-dark-grey-body '
              onClick={storeBoxReduxStore}
              disabled={box.disabled}
            >
              {t(box.label)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

PatientPortalCard.propTypes = {
  box: PropTypes.object.isRequired,
  style: PropTypes.string,
  handleClick: PropTypes.func,
  pathway: PropTypes.any,
  last_trainrg: PropTypes.any,
};

PatientPortalCard.defaultProps = {
  options: {},
};

export default connect(mapStateToProps, null)(PatientPortalCard);
