import React from 'react';
import PropTypes from 'prop-types';
import SelectIdiom from './SelectIdiom';
import Container from '../container/Container';
// import logo from '../../data/mdt_logo_white.png';
import logo from '../../data/art_logo_en_rgb_bl.png';
import routes from '../../helpers/routes';

const pathname = window.location.pathname;
var getPathLocation = pathname?.includes(routes.dashboard.index);
export default function HeaderMedtronic({ isLoggedIn, ...props }) {
  return (
    <Container>
      <div className={`flex justify-between items-center ${isLoggedIn} `}>
        <div>
          <img src={logo} width='180' height='32' alt='Logo' />
        </div>
        <div className={`${getPathLocation ? 'hidden' : ''}`}>
          {/* //! Hidden for admin page */}
          <SelectIdiom getPathLocation={getPathLocation} {...props} />
        </div>
      </div>
    </Container>
  );
}

HeaderMedtronic.propTypes = {
  props: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  getPathLocation: PropTypes.any,
};
