export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        //alert: 'cvent.steps.gb.alert_title',
        disabled: false,
      },
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/for-kids.jpg',
        // device: '/workflow/G4S_system.png',
        description: 'cvent.cards.second_step.description',
        //alert: 'cvent.steps.gb.alert_title',
        disabled: false,
      },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '3',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '3',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_prescribed_pump_title',
    stepDescription: 'cvent.steps.cvent_check_if_prescribed_pump_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '3',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '3',
        to: '4.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4.1',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued_2',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.gb.entry_level_title',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '5',
        to: 'cgm_and_smartguard',
        title: 'cvent.steps.gb.cgm_and_smartguard',
        label: '',
      },
      {
        from: '5',
        to: 'smartguard',
        title: 'cvent.steps.gb.smartguard',
        label: '',
      },
      {
        from: '5',
        to: 'MM780GFSU',
        title: 'cvent.steps.gb.minimed',
        label: '',
      },
      {
        from: '5',
        to: 'MM780GPumpandCGM',
        title: 'cvent.steps.gb.minimed_pump_and_cgm',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'cgm_and_smartguard',
    centered: true,
    stepName: 'cvent.steps.gb.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.gb.select_product',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      // {
      //   from: 'cgm_and_smartguard',
      //   to: 'Summary',
      //   title: 'cvent.steps.gb.mm_780g',
      //   label: '',
      // },
      {
        from: 'cgm_and_smartguard',
        to: 'Summary',
        title: 'cvent.steps.gb.guardian_4',
        label: '',
      },
      {
        from: 'cgm_and_smartguard',
        to: 'Summary',
        title: 'cvent.steps.gb.simplera_sync',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'smartguard',
    centered: true,
    stepName: 'cvent.steps.gb.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.gb.select_product',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      // {
      //   from: 'smartguard',
      //   to: 'Summary',
      //   title: 'cvent.steps.gb.mm_780g',
      //   label: '',
      // },
      {
        from: 'cgm_and_smartguard',
        to: 'Summary',
        title: 'cvent.steps.gb.guardian_4',
        label: '',
      },
      {
        from: 'cgm_and_smartguard',
        to: 'Summary',
        title: 'cvent.steps.gb.simplera_sync',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'MM780GFSU',
    centered: true,
    stepName: 'cvent.steps.gb.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.gb.select_product',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'cgm_and_smartguard',
        to: 'Summary',
        title: 'cvent.steps.gb.guardian_4',
        label: '',
      },
      {
        from: 'cgm_and_smartguard',
        to: 'Summary',
        title: 'cvent.steps.gb.simplera_sync',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'MM780GPumpandCGM',
    centered: true,
    stepName: 'cvent.steps.gb.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.gb.select_product',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'cgm_and_smartguard',
        to: 'Summary',
        title: 'cvent.steps.gb.guardian_4',
        label: '',
      },
      {
        from: 'cgm_and_smartguard',
        to: 'Summary',
        title: 'cvent.steps.gb.simplera_sync',
        label: '',
      },
    ],
  },
];
