export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        alert: 'cvent.cards.first_step.alert',
        disabled: false,
      },
      // {
      //   from: '1',
      //   //to: '2',
      //   title: 'cvent.cards.second_step.title',
      //   label: 'cvent.cards.second_step.button',
      //   image: '/workflow/guardian.jpg',
      //   device: '/workflow/smartGuard.png',
      //   description: 'cvent.cards.second_step.description',
      //   disabled: true,
      //   skipFlow: true,
      //   flowTags: [
      //     {
      //       key: 'Training',
      //       value: 'SmartGuard',
      //     },
      //   ],
      // },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '6',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: '',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: '',
      },
      {
        from: '2',
        to: '4.1',
        title: 'cvent.steps.cvent_check_if_product_exist',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4.1',
    centered: true,
    stepName: 'cvent.steps.cvent_info_if_product_exist',
    stepDescription: 'cvent.steps.cvent_info_if_product_exist',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '4.1',
        to: '4.2',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '4.2',
    centered: true,
    stepName: 'cvent.steps.cvent_info_if_product_exist2',
    stepDescription: 'cvent.steps.cvent_info_if_product_exist2',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '4.2',
        to: '6.2',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '6',
    centered: true,
    stepName: 'cvent.steps.cvent_pump_user',
    stepDescription: 'cvent.steps.cvent_pump_user',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6',
        to: 'under17',
        title: 'cvent.steps.cvent_17_years',
        label: '',
      },
      {
        from: '6',
        to: '7',
        title: 'cvent.steps.cvent_18_years',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6.2',
    centered: true,
    stepName: 'cvent.steps.cvent_pump_user',
    stepDescription: 'cvent.steps.cvent_pump_user',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6.2',
        to: 'under17_exist_product',
        title: 'cvent.steps.cvent_17_years',
        label: '',
      },
      {
        from: '6.2',
        to: '7.2',
        title: 'cvent.steps.cvent_18_years',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'under17',
    centered: true,
    stepName: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDescription: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'under17',
        to: '7',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: 'under17_exist_product',
    centered: true,
    stepName: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDescription: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'under17_exist_product',
        to: '7.2',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.diabetes_center',
    stepDescription: 'cvent.steps.diabetes_center',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      // {
      //   from: '7',
      //   to: 'experience_question_for_other_region',
      //   title: 'cvent.steps.cvent_first_center',
      //   label: '',
      // },
      {
        from: '7',
        to: 'cvent_if_exp_yes_for_central',
        title: 'cvent.steps.cvent_already_have_experience_with_med_yes',
        label: '',
      },
      {
        from: '7',
        to: '11',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '7.2',
    centered: true,
    stepName: 'cvent.steps.diabetes_center',
    stepDescription: 'cvent.steps.diabetes_center',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7.2',
        to: 'info_for_other_region',
        title: 'cvent.steps.cvent_first_center',
        label: '',
      },
      {
        from: '7.2',
        to: 'info_for_central_region',
        title: 'cvent.steps.cvent_second_center',
        label: '',
      },
      {
        from: '7.2',
        to: 'info_for_other_region',
        title: 'cvent.steps.cvent_third_center',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'info_for_central_region',
    centered: true,
    stepName: 'cvent.steps.cvent_info_for_central_region',
    stepDescription: 'cvent.steps.cvent_info_for_central_region',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'info_for_central_region',
        to: 'cvent_if_exp_yes_for_central',
        title: 'cvent.steps.cvent_already_have_experience_with_med_yes',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'info_for_other_region',
    centered: true,
    stepName: 'cvent.steps.cvent_info_for_other_region',
    stepDescription: 'cvent.steps.cvent_info_for_other_region',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'info_for_other_region',
        to: '11',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  // {
  //   stepNumber: 'experience_question_for_central_region', //!REMOVE
  //   centered: true,
  //   stepName: 'cvent.steps.cvent_already_have_experience_with_med_title',
  //   stepDescription: 'cvent.steps.cvent_already_have_experience_with_med_title',
  //   stepDisclaimer: '',
  //   stepCategory: 'disable_breadcumbs',
  //   componentType: 'YesOrNoSelection',
  //   options: [
  //     {
  //       from: 'experience_question_for_central_region',
  //       to: 'cvent_if_exp_yes_for_central',
  //       title: 'cvent.steps.cvent_already_have_experience_with_med_yes',
  //       label: '',
  //     },
  //     {
  //       from: 'experience_question_for_central_region',
  //       to: 'cvent_if_exp_no_for_central',
  //       title: 'cvent.steps.cvent_already_have_experience_with_med_no',
  //       label: '',
  //     },
  //   ],
  // },
  // {
  //   stepNumber: 'experience_question_for_other_region',
  //   centered: true,
  //   stepName: 'cvent.steps.cvent_already_have_experience_with_med_title', //! REMOVE
  //   stepDescription: 'cvent.steps.cvent_already_have_experience_with_med_title',
  //   stepDisclaimer: '',
  //   stepCategory: 'disable_breadcumbs',
  //   componentType: 'YesOrNoSelection',
  //   options: [
  //     {
  //       from: 'experience_question_for_other_region',
  //       to: '11',
  //       title: 'cvent.steps.cvent_check_continue_flow',
  //       label: 'cvent.steps.cvent_check_continue_flow',
  //     },
  //     {
  //       from: 'experience_question_for_other_region',
  //       to: 'cvent_if_exp_no_for_other',
  //       title: 'cvent.steps.cvent_already_have_experience_with_med_no',
  //       label: '',
  //     },
  //   ],
  // },
  {
    stepNumber: 'cvent_if_exp_yes_for_central',
    centered: true,
    stepName: 'cvent.steps.cvent_exp_yes_for_central',
    stepDescription: 'cvent.steps.cvent_exp_yes_for_central',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'cvent_if_exp_yes_for_central',
        to: '11',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  // {
  //   stepNumber: 'cvent_if_exp_yes_for_other',
  //   centered: true,
  //   stepName: 'cvent.steps.cvent_exp_yes_for_other',  //! remove this
  //   stepDescription: 'cvent.steps.cvent_exp_yes_for_other',
  //   stepDisclaimer: '',
  //   stepCategory: 'disable_breadcumbs',
  //   componentType: 'YesOrNoSelection',
  //   options: [
  //     {
  //       from: 'cvent_if_exp_yes_for_other',
  //       to: '11',
  //       title: 'cvent.steps.cvent_check_continue_flow',
  //       label: 'cvent.steps.cvent_check_continue_flow',
  //     },
  //   ],
  // },
  {
    stepNumber: 'cvent_if_exp_no_for_central',
    centered: true,
    stepName: 'cvent.steps.cvent_first_training_with_exp_title',
    stepDescription: 'cvent.steps.cvent_first_training_with_exp_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'cvent_if_exp_no_for_central',
        to: 'yes_to_1st_training_for_central',
        title: 'cvent.steps.cvent_first_training_with_exp_yes',
        label: '',
      },
      {
        from: 'cvent_if_exp_no_for_central',
        to: 'no_to_1st_training_for_central',
        title: 'cvent.steps.cvent_first_training_with_exp_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'cvent_if_exp_no_for_other',
    centered: true,
    stepName: 'cvent.steps.cvent_first_training_with_exp_title',
    stepDescription: 'cvent.steps.cvent_first_training_with_exp_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'cvent_if_exp_no_for_other',
        to: '11',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
      {
        from: 'cvent_if_exp_no_for_other',
        to: '11',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: 'yes_to_1st_training_for_central',
    centered: true,
    stepName: 'cvent.steps.first_training_info_for_central',
    stepDescription: 'cvent.steps.first_training_info_for_central',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yes_to_1st_training_for_central',
        to: '11',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  // {
  //   stepNumber: 'yes_to_1st_training_for_other',
  //   centered: true,
  //   stepName: 'cvent.steps.first_training_info_for_other',  //! remove this
  //   stepDescription: 'cvent.steps.first_training_info_for_other',
  //   stepDisclaimer: '',
  //   stepCategory: 'disable_breadcumbs',
  //   componentType: 'YesOrNoSelection',
  //   options: [
  //     {
  //       from: 'yes_to_1st_training_for_other',
  //       to: '11',
  //       title: 'cvent.steps.cvent_check_continue_flow',
  //       label: 'cvent.steps.cvent_check_continue_flow',
  //     },
  //   ],
  // },
  {
    stepNumber: 'no_to_1st_training_for_central',
    centered: true,
    stepName: 'cvent.steps.no_exp_with_first_training_info_for_central',
    stepDescription: 'cvent.steps.no_exp_with_first_training_info_for_central',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'no_to_1st_training_for_central',
        to: '11',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  // {
  //   stepNumber: 'no_to_1st_training_for_other',
  //   centered: true,
  //   stepName: 'cvent.steps.no_exp_with_first_training_info_for_other', //!remove this
  //   stepDescription: 'cvent.steps.no_exp_with_first_training_info_for_other',
  //   stepDisclaimer: '',
  //   stepCategory: 'disable_breadcumbs',
  //   componentType: 'YesOrNoSelection',
  //   options: [
  //     {
  //       from: 'no_to_1st_training_for_other',
  //       to: '11',
  //       title: 'cvent.steps.cvent_check_continue_flow',
  //       label: 'cvent.steps.cvent_check_continue_flow',
  //     },
  //   ],
  // },
  {
    stepNumber: '11',
    centered: true,
    stepName: 'cvent.steps.cvent_select_format_title',
    stepDescription: 'cvent.steps.cvent_select_format_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '11',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Virtual',
          },
        ],
        title: 'cvent.steps.cvent_virtual',
        label: '',
      },
      {
        from: '11',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Face to Face',
          },
        ],
        title: 'cvent.steps.cvent_face_to_face',
        label: '',
      },
    ],
  },
];
