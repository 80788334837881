export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: 'yesNoQuestion',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'yesNoQuestion2',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/guardian.jpg',
        device: '/workflow/guardian-cgm.png',
        description: 'cvent.cards.second_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'yesNoQuestion3',
        ESTextSize: true,
        title: 'cvent.cards.third_step.title',
        label: 'cvent.cards.third_step.button',
        image: '/workflow/special-training.jpg',
        device: '/workflow/guardian-cgm.png',
        description: 'cvent.cards.third_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'yesNoQuestion6',
        title: 'cvent.cards.fourth_step.title',
        label: 'cvent.cards.fourth_step.button',
        image: '/workflow/es_holding_inpen.jpg',
        device: '/workflow/guardian-cgm.png',
        description: 'cvent.cards.fourth_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'sessionSelection',
        title: 'cvent.cards.fifth_step.title',
        label: 'cvent.cards.fifth_step.button',
        image: '/workflow/sport_minimed_780g.jpg',
        device: '/workflow/guardian-cgm.png',
        description: 'cvent.cards.fifth_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'sessionSelection2',
        title: 'cvent.cards.six_step.title',
        label: 'cvent.cards.six_step.button',
        image: '/workflow/es_mm780g.jpg',
        device: '/workflow/guardian-cgm.png',
        description: 'cvent.cards.six_step.description',
        disabled: false,
      },
    ],
  },
  {
    stepNumber: 'yesNoQuestion',
    centered: true,
    stepName: 'cvent.steps.cvent_yesNoQuestion',
    stepDescription: 'cvent.steps.cvent_yesNoQuestion',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yesNoQuestion',
        to: 'sessionQuestion',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'yesNoQuestion',
        to: 'noAnswerGoBack',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'yesNoQuestion2', //!Here should be the ES Images
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yesNoQuestion2',
        to: 'session1Question',
        ESshouldDisplayImages: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'yesNoQuestion2',
        to: 'noAnswerGoBackCard2',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'yesNoQuestion3',
    centered: true,
    stepName: 'cvent.steps.cvent_training_question_title',
    stepDescription: 'cvent.steps.cvent_training_question_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yesNoQuestion3',
        to: 'yesNoQuestion4',
        ESTextSize: true,
        title: 'cvent.steps.cvent_carelink_answer',
        label: 'cvent.steps.cvent_carelink_answer',
      },
      {
        from: 'yesNoQuestion3',
        to: 'yesNoQuestion5',
        ESTextSize: true,
        title: 'cvent.steps.cvent_sensor_answer',
        label: 'cvent.steps.cvent_sensor_answer',
      },
    ],
  },
  {
    stepNumber: 'yesNoQuestion4',
    centered: true,
    stepName: 'cvent.steps.cvent_carelink_info_title',
    stepDescription: 'cvent.steps.cvent_carelink_info_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yesNoQuestion4',
        //to: 'Summary'
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Carelink',
          },
        ],
        ESshouldDisplayImages2: true,
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: 'yesNoQuestion5',
    centered: true,
    stepName: 'cvent.steps.cvent_sensor_info_title',
    stepDescription: 'cvent.steps.cvent_sensor_info_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yesNoQuestion5',
        //to: 'Summary'
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Sensor',
          },
        ],
        ESshouldDisplayImages3: true,
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: 'yesNoQuestion6',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_simplera_arrived',
    stepDescription: 'cvent.steps.cvent_check_if_product_simplera_arrived',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yesNoQuestion6',
        to: 'session1QuestionSimplera',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'yesNoQuestion6',
        to: 'noAnswerGoBackCard2',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'noAnswerGoBack',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'noAnswerGoBackCard2',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued2',
    stepDescription: 'cvent.steps.cvent_registration_cannot_be_continued2',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'sessionQuestion',
    centered: true,
    stepName: 'cvent.steps.sessionQuestion',
    stepDescription: 'cvent.steps.sessionQuestion',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionQuestion',
        to: 'session3toContinue',
        title: 'cvent.steps.session3',
        label: 'cvent.steps.session3',
      },
      {
        from: 'sessionQuestion',
        to: 'Session4YesNoQuestion',
        title: 'cvent.steps.session4',
        label: 'cvent.steps.session4',
      },
    ],
  },
  {
    stepNumber: 'sessionQuestionNoAnswer',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'session3toContinue',
    centered: true,
    stepName: 'cvent.steps.session3toContinue',
    stepDescription: 'cvent.steps.session3toContinue',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session3toContinue',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Product',
            value: 'Session 3',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: 'Session4YesNoQuestion',
    centered: true,
    stepName: 'cvent.steps.Session4YesNoQuestion',
    stepDescription: 'cvent.steps.Session4YesNoQuestion',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'Session4YesNoQuestion',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Product',
            value: 'Session 4',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'Session4YesNoQuestion',
        to: 'Session4YesNoQuestionNoSelection',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'Session4YesNoQuestionNoSelection',
    centered: true,
    stepName: 'cvent.steps.Session4YesNoQuestionNoSelection',
    stepDescription: 'cvent.steps.Session4YesNoQuestionNoSelection',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'session1Question',
    centered: true,
    stepName: 'cvent.steps.session1Question',
    stepDescription: 'cvent.steps.session1Question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session1Question',
        to: 'session1QuestionContinue',
        title: 'cvent.steps.session1',
        label: 'cvent.steps.session1',
      },
    ],
  },
  {
    stepNumber: 'sessionSelection',
    centered: true,
    stepName: 'cvent.steps.sessionSelection',
    stepDescription: 'cvent.steps.sessionSelection',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionSelection',
        to: 'session2QuestionContinue',
        title: 'cvent.steps.session2',
        label: 'cvent.steps.session2',
      },
    ],
  },
  {
    stepNumber: 'sessionSelection2',
    centered: true,
    stepName: 'cvent.steps.sessionSelection',
    stepDescription: 'cvent.steps.sessionSelection2',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionSelection',
        to: 'session2toendsummary',
        title: 'cvent.steps.session5',
        label: 'cvent.steps.session5',
      },
    ],
  },
  {
    stepNumber: 'session1QuestionSimplera',
    centered: true,
    stepName: 'cvent.steps.session1QuestionSimplera',
    stepDescription: 'cvent.steps.session1QuestionSimplera',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session1QuestionSimplera',
        to: 'simpleraQuestionContinue',
        title: 'cvent.steps.simplera',
        label: 'cvent.steps.simplera',
      },
    ],
  },
  {
    stepNumber: 'session1QuestionContinue',
    centered: true,
    stepName: 'cvent.steps.session1QuestionContinue',
    stepDescription: 'cvent.steps.session1QuestionContinue',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session1QuestionContinue',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Product',
            value: 'Session 1',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: 'session2QuestionContinue',
    centered: true,
    stepName: 'cvent.steps.session2QuestionContinue',
    stepDescription: 'cvent.steps.session2QuestionContinue',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session2QuestionContinue',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Deporte 780G',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: 'session2toendsummary',
    centered: true,
    stepName: 'cvent.steps.session2toendsummary',
    stepDescription: 'cvent.steps.session2toendsummary',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionSelection2',
        to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Situaciones especiales 780G',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: 'simpleraQuestionContinue',
    centered: true,
    stepName: 'cvent.steps.simpleraQuestionContinue',
    stepDescription: 'cvent.steps.simpleraQuestionContinue',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'simpleraQuestionContinue',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Sensor Simplera',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
];
