/* eslint-disable no-unused-vars */
/* eslint-disable indent */

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Label from '../../label/Label';
import Text from '../../forms/inputs/Text';
import DatePicker from '../../forms/inputs/DatePicker';
import Button from '../../button/Button';
import Select, { getMapFromArray } from '../../forms/inputs/Select';
import { LoadingFullScreenCentered } from '../../loading-indicator/Loading';
import { COUNTRIES } from '../../../constants/cvent';
import ItemList from '../../item-list/ItemList';
import Layout from '../../forms/Layout';
import { ZA_REGIONS, NL_HOSPITAL } from '../../../constants/form';
import { store } from '../../../store/redux';

const NETHERLANDS_FORMAT = 'dd/MM/yyyy';

export function FormSelection(props) {
  const {
    submit,
    loading,
    stepName,
    loadingHospitals,
    data,
    handleBack,
    user,
    setCustomFlow,
  } = props;
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    date_of_birth: null,
    hospital: '',
    za_region: '',
    za_representative: '',
    za_medtronic_specialist: '',
    za_sales_representative: '',
    za_educator: '',
    za_sales_representative_educators: '',
    organizerEmailList:'',
  });
  const cardSelected = store.getState().pathwayReducer.box;

  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(formData);
  };
  const [za_region_selected, setza_region_selected] = useState({});

  function esHospitalandProvinceSelected(value) {
    var region = ZA_REGIONS.find((o) => o.label === value);

    setza_region_selected(region);
    setFormData({
      ...formData,
      za_region: value,
      za_representative: '',
      za_medtronic_specialist: '',
      za_sales_representative: '',
      za_educator: '',
    });
  }

  if (loadingHospitals) {
    return <LoadingFullScreenCentered />;
  }

  if (data && data.hospitals.length === 0) {
    return <Label label={t('something_went_wrong')} />;
  }

  return (
    <Layout
      handleBack={handleBack}
      heading={
        <div className='mb-5'>
          <Label label={t(stepName)} />
        </div>
      }
    >
      <ValidatorForm onSubmit={handleSubmit}>
        {user.country.alpha2 === COUNTRIES.NETHERLANDS.toUpperCase() && (
          <div className='p-8'>
            <Text
              placeholder={`${t('cvent.form.first_name')} *`}
              name='first_name'
              disabled={loading}
              value={formData.first_name}
              validators={['required', 'maxStringLength:50']}
              errorMessages={[
                t('registration_error_required'),
                t('registration_error_too_long'),
              ]}
              onChange={(e) => {
                setFormData({ ...formData, first_name: e });
              }}
            />
            <Text
              placeholder={`${t('cvent.form.last_name')} *`}
              name='last_name'
              disabled={loading}
              value={formData.last_name}
              validators={['required', 'maxStringLength:50']}
              errorMessages={[
                t('registration_error_required'),
                t('registration_error_too_long'),
              ]}
              onChange={(e) => {
                setFormData({ ...formData, last_name: e });
              }}
            />
            <Text
              placeholder={`${t('cvent.form.phone_number')} *`}
              name='phone_number'
              disabled={loading}
              value={formData.phone_number}
              validators={[
                'required',
                'matchRegexp:^[+0-9- ]*$',
                'maxStringLength:20',
              ]}
              errorMessages={[
                t('registration_error_required'),
                t('registration_error_valid_input'),
                t('registration_error_too_long'),
              ]}
              onChange={(e) => {
                setFormData({ ...formData, phone_number: e });
              }}
            />
            <DatePicker
              format={NETHERLANDS_FORMAT}
              placeholder={`${t('cvent.form.date_of_birth')} *`}
              disabled={loading}
              name='date_of_birth'
              value={formData.date_of_birth}
              validators={['required']}
              errorMessages={[t('registration_error_required')]}
              okLabel=''
              cancelLabel=''
              variant='dialog'
              openTo='year'
              InputProps={{ readOnly: true }}
              onChange={(e) => {
                setFormData({ ...formData, date_of_birth: e }),
                  setIsOpen(false);
              }}
              onClick={(e) => {
                setIsOpen(true);
              }}
              open={isOpen}
            />
            <Select
              name='hospital'
              disabled={loading}
              validators={['required']}
              errorMessages={[t('registration_error_required')]}
              value={formData.hospital}
              onChange={(e) => {
                const selectedHospital = e.toLowerCase(); 
                const filteredHospitals = NL_HOSPITAL.filter(
                  (o) => o.label.toLowerCase() === selectedHospital 
                );
                if (filteredHospitals.length > 0) {
                  setCustomFlow(true);
                } else {
                  setCustomFlow(false);
                }
                setFormData({ ...formData, hospital: e }); 
              }}
              selectItems={
                <>
                  <option value='' disabled>
                    {t('cvent.form.hospital')}
                  </option>
                  <ItemList
                    items={getMapFromArray(data.hospitals)}
                    translate={false}
                  />
                </>
              }
            />
          </div>
        )}

        {user.country.alpha2 === COUNTRIES.SOUTH_AFRICA.toUpperCase() && (
          <div>
            {/* {cardSelected.description ===
              'cvent.cards.first_step.description' && (
              <div>
                <Select
                  name='za_region'
                  disabled={loading}
                  validators={['required']}
                  errorMessages={[t('registration_error_required')]}
                  value={formData.za_region}
                  onChange={(e) => {
                    esHospitalandProvinceSelected(e);
                  }}
                  selectItems={
                    <>
                      <option value='' disabled>
                        {t('cvent.steps.za.za_region')}
                      </option>
                      <ItemList items={ZA_REGIONS} translate={false} />
                    </>
                  }
                />

                <Select
                  name='za_representative'
                  disabled={loading}
                  validators={['required']}
                  errorMessages={[t('registration_error_required')]}
                  value={formData.za_representative}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      za_representative: e,
                    });
                  }}
                  selectItems={
                    <>
                      <option value='' disabled>
                        {t('cvent.steps.za.za_representative')}
                      </option>
                      <ItemList
                        items={za_region_selected.representative}
                        translate={false}
                      />
                    </>
                  }
                />
              </div>
            )} */}

            <Select
              name='za_region'
              disabled={loading}
              validators={['required']}
              errorMessages={[t('registration_error_required')]}
              value={formData.za_region}
              onChange={(e) => {
                esHospitalandProvinceSelected(e);
              }}
              selectItems={
                <>
                  <option value='' disabled>
                    {t('cvent.steps.za.za_region')}
                  </option>
                  <ItemList items={ZA_REGIONS} translate={false} />
                </>
              }
            />
            {cardSelected.description ===
              'cvent.cards.first_step.description' && (
              <div>
                {formData.za_region.length > 0 && (
                  <Select
                    name='za_medtronic_specialist'
                    disabled={loading}
                    validators={['required']}
                    errorMessages={[t('registration_error_required')]}
                    value={formData.za_medtronic_specialist}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        za_medtronic_specialist: e,
                      });
                    }}
                    selectItems={
                      <>
                        <option value='' disabled>
                          {t('cvent.steps.za.za_medtronic_specialist')}
                        </option>
                        <ItemList
                          items={za_region_selected.representative}
                          translate={false}
                        />
                      </>
                    }
                  />
                )}
              </div>
            )}
            {cardSelected.description ===
              'cvent.cards.second_step.description' && (
              <div>
                {formData.za_region.length > 0 &&
                  za_region_selected.representative.length > 1 && (
                    <Select
                      name='za_sales_representative'
                      disabled={loading}
                      validators={['required']}
                      errorMessages={[t('registration_error_required')]}
                      value={formData.za_sales_representative}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          za_sales_representative: e,
                          za_sales_representative_educators:
                            za_region_selected.representative.find(
                              (representative) => representative.label === e
                            ).educator,
                        });
                      }}
                      selectItems={
                        <>
                          <option value='' disabled>
                            {t('cvent.steps.za.za_sales_representative')}
                          </option>
                          <ItemList
                            items={za_region_selected.representative}
                            translate={false}
                          />
                        </>
                      }
                    />
                  )}
              </div>
            )}
            {cardSelected.description ===
              'cvent.cards.second_step.description' && (
              <div>
                {formData.za_sales_representative.length > 0 && (
                  <Select
                    name='za_educator'
                    disabled={loading}
                    validators={['required']}
                    errorMessages={[t('registration_error_required')]}
                    value={formData.za_educator}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        za_educator: e,
                      });
                    }}
                    selectItems={
                      <>
                        <option value='' disabled>
                          {t('cvent.steps.za.za_educator')}
                        </option>
                        <ItemList
                          items={formData.za_sales_representative_educators}
                          translate={false}
                        />
                      </>
                    }
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className='flex justify-end mb-4'>
          <Button
            className='rounded-full text-dark-grey-body px-4 mr-8'
            type='submit'
            disabled={loading}
          >
            {t('global.next')}
          </Button>
        </div>
      </ValidatorForm>
    </Layout>
  );
}

FormSelection.propTypes = {
  submit: PropTypes.func,
  loading: PropTypes.bool,
  loadingHospitals: PropTypes.bool,
  data: PropTypes.object,
  handleBack: PropTypes.func,
  user: PropTypes.object,
  stepName: PropTypes.string,
  setCustomFlow: PropTypes.func,
};

export default FormSelection;
