/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import Event from '../schema/Event';
import BigCalendar from '../components/calendar/BigCalendar';
import SearchBar from '../components/SearchBar';
import { MONTH, WEEK } from '../constants/calendar';
import { LoadingFullScreenCentered } from '../components/loading-indicator/Loading';
import NoEventsFound from '../components/calendar/NoEventsFound';
import Dialog from '../components/dialog/Dialog';
import RegisterFollowUp from '../components/calendar/follow-up-modal/RegisterFollowUp';
import routes from '../helpers/routes';
import { TimeWindow } from '../helpers/CalendarEventWindowTime';

const mapStateToProps = (state) => {
  return {
    tags: state.tagsReducer.tags,
    user: state.userReducer.user,
    pathway: state.pathwayReducer.pathway,
    last_trainrg: state.tagsReducer.last_trainrg,
    initial_tags: state.tagsReducer.initial_tags,
    last_event: state.tagsReducer.last_event,
    localeReducerCalendar:
      state.localeReducer.notificationLanguage.notificationCalendar,
    localeReducerNotificationLanguage:
      state.localeReducer.notificationLanguage.notificationLanguage,
    localeReducerNotificationAcceptLanguage:
      state.localeReducer.notificationLanguage.notificationAcceptLanguage,
  };
};

const useStyles = makeStyles(() => ({
  containerStyle: {
    height: '100%',
  },
}));

export function CalendarScreen({
  tags,
  pathway,
  user,
  last_trainrg,
  last_event,
  localeReducerCalendar,
  localeReducerNotificationLanguage,
  localeReducerNotificationAcceptLanguage,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles({});
  const country = user && user.country ? user.country.alpha2 : null;
  const [view, setView] = useState(MONTH);

  const [dateNextPerv, setDateNextPerv] = useState(false);
  const [dayPrevNextButton, setdayPrevNextButton] = useState(0);

  const calendarTimeFilter = TimeWindow[user.country.alpha2] || [
    { calendarEventSearchWeeks: 12 },
  ];

  const START_DATE = moment();
  const END_DATE = moment().add(
    calendarTimeFilter[0].calendarEventSearchWeeks,
    'week'
  );

  const [search, setSearch] = useState({
    fromDate: START_DATE.unix(),
    toDate: END_DATE.unix(),
    country,
    coords: null,
    city: user.city,
    isOnline: true,
    isFaceToFace: true,
    language: localeReducerCalendar,
    // language: '',
  });

  const [fetchEvents, { data: events, loading, error }] = useLazyQuery(
    Event.GET_ALL_EVENTS
  );

  const userCountry = user.country ? user.country.alpha2 : '';

  const timeFrameWindow = TimeWindow[userCountry] || [{ timeToAdd: 259200 }];

  useEffect(() => {
    if (tags.length) {
      fetchEvents({
        variables: {
          fromDate: search.fromDate,
          toDate: search.toDate,
          country: search.country,
          coords: !search.isOnline ? search.coords : null,
          language: search.language ? [search.language] : null,
          city: search.city ? [search.city] : null,
          tags,
        },
      });
    }
  }, [search, fetchEvents, tags]);

  const onSearch = (data) => {
    const searchTerms = {
      fromDate: START_DATE.unix(),
      toDate: search.toDate,
      isOnline: data.isOnline,
      isFaceToFace: data.isFaceToFace,
      language: data.language,
      city: data.city,
    };

    let coords = null;

    // if (data.city && data.city.id) {
    //   const city = data.city;

    //   coords = {
    //     fromCoords: {
    //       lat: Number(city.lat),
    //       lng: Number(city.lng),
    //     },
    //     distance: Number(data.distance || 20) * 1000,
    //   };
    // }

    searchTerms.coords = coords;

    if (data.country) {
      searchTerms.country = data.country;
    }

    setSearch(searchTerms);
  };

  const onRangeChange = (range) => {
    const dateRange = {};

    if (range.length) {
      dateRange.fromDate = moment(range[0]);
      dateRange.toDate = moment(range.at(-1));
    } else {
      dateRange.fromDate = moment(range.start);
      dateRange.toDate = moment(range.end);
    }

    // view can be month / week / agenda
    if (view == 'month') {
      setdayPrevNextButton(
        new Date(moment(range.start).endOf().add(1, 'week').unix() * 1000)
      );

      if (range.start < new Date(END_DATE.unix() * 1000)) {
        setDateNextPerv(true);
        setSearch({
          ...search,
          fromDate: moment(range.start).unix(),
          toDate: moment(range.end).unix(),
        });
      }
    } else {
      setSearch({
        ...search,
        fromDate: moment(dateRange.fromDate).unix(),
        toDate: moment(dateRange.toDate).unix(),
      });
    }

    //!Old code start------------------------
    // const dateRange = {};

    // if (range.length) {
    //   dateRange.fromDate = moment(range[0]);
    //   dateRange.toDate = moment(range[range.length - 1]);
    // } else {
    //   dateRange.fromDate = moment(range.start);
    //   dateRange.toDate = moment(range.end);
    // }

    // setSearch({
    //   ...search,
    //   fromDate: dateRange.fromDate.isBefore(START_DATE)
    //     ? START_DATE.unix()
    //     : dateRange.fromDate.unix(),
    //   toDate: dateRange.toDate.unix(),
    // });
    //!Old code end------------------------
  };

  const toHome = useCallback(() => {
    history.replace(routes.app.index);
  }, [history]);

  const fetchedEvents = events && events.eventSearch ? events.eventSearch : [];
  const filteredEvents = fetchedEvents
    .filter((appointment) => {
      if (search.isOnline && search.isFaceToFace) {
        return true;
      }

      if (search.isOnline) {
        return appointment.isOnline;
      }

      if (search.isFaceToFace) {
        return !appointment.isOnline;
      }
    })
    .filter((appointment) => {
      if (localeReducerCalendar === 'gb' || localeReducerCalendar === 'za') {
        return search.language
          ? appointment.language.includes('en')
          : appointment;
      } else {
        if (
          localeReducerNotificationAcceptLanguage.includes(appointment.language[0])
        ) {
          return appointment;
        } else if (appointment.language.includes(localeReducerCalendar)) {
          return appointment;
        }
      }
    })
    .filter((appointment) => {
      if (
        search.isOnline ||
        (!search.isOnline && search.city?.administrativeAreaLevel2 == undefined)
      ) {
        return appointment;
      } else if (
        search.city?.administrativeAreaLevel2 &&
        appointment.city.administrativeAreaLevel2 ===
          search.city.administrativeAreaLevel2
      ) {
        return appointment;
      }
      // search.city?.administrativeAreaLevel2
      //   ? appointment.city.administrativeAreaLevel2 ===
      //     search.city.administrativeAreaLevel2
      //   : appointment;
    })
    .filter((appointment) => {
      var today = Math.round(new Date().getTime() / 1000);
      var todayPlusDays = today + timeFrameWindow[0].timeToAdd;
      if (
        (pathway?.id.length > 0 && pathway !== null) ||
        (last_trainrg?.length > 0 && last_trainrg !== null)
      ) {
        //Pathwats events

        //check if its a pathway ver 1 and 2
        // if one of 2 is true then it will confirm its pathwasy and it will check the last event registered too so it can set the date in the future
        if (appointment.start > last_event.event.end) {
          if (appointment.pathway === null) {
            return appointment;
          } else {
            return appointment.id;
          }
        }
      } else {
        //Single Events
        if (appointment.start > todayPlusDays) {
          return appointment;
        }
      }
    });
  // const restult = new Date(search.fromDate * 9000);

  const dateToChangeTo = (filteredEventsDate) => {
    if (dateNextPerv === false) {
      if (filteredEvents.length > 0) {
        // return new Date(filteredEventsDate[0].start * 1000);
        return new Date(
          Math.min(...filteredEventsDate.map((item) => item.start)) * 1000
        );
      } else if (pathway?.descendants[0]?.start) {
        // return new Date(pathway.descendants[0]?.start * 1000);
        return new Date(
          Math.min(...pathway.descendants.map((item) => item.start)) * 1000
        );
      }
    }
  };

  return (
    <div className='bg-atmospheric-white sm:-px-5'>
      <div className='pt-5 pb-5 '>
        {user.country.alpha2.toUpperCase() !== 'GB' &&
          user.country.alpha2.toUpperCase() !== 'FI' &&
          user.country.alpha2.toUpperCase() !== 'SE' &&
          user.country.alpha2.toUpperCase() !== 'DK' && (
            <SearchBar
              country={search.country}
              isOnline={search.isOnline}
              isFaceToFace={search.isFaceToFace}
              city={search.city}
              language={search.language}
              onSearch={onSearch}
            />
          )}
      </div>

      {/* {error && !loading && <p>{t('something_went_wrong')}</p>} */}
      {error && !loading && (
        <div>
          <p className='p-5 mt-5 font-bold text-center uppercase text-red'>
            {t('something_went_wrong')}
          </p>
          <br />
        </div>
      )}

      <div className={classes.containerStyle}>
        <div className='relative h-screen mb-10 h-calendar max-h-calendar'>
          {!loading &&
          !(filteredEvents?.length > 0 || pathway?.descendants?.length > 0) ? (
            <NoEventsFound className='absolute z-40 w-full mt-10 h-no-events-overlay' />
          ) : (
            <></>
          )}

          {loading && <LoadingFullScreenCentered />}

          <BigCalendar
            // startDate={search.fromDate}
            events={
              pathway && pathway.descendants
                ? pathway.descendants
                : filteredEvents
            }
            onViewChange={(view) => setView(view)}
            onRangeChange={onRangeChange}
            view={view}
            date={
              view != 'month'
                ? dateToChangeTo(filteredEvents)
                : dateNextPerv == false
                ? dateToChangeTo(filteredEvents)
                : dayPrevNextButton
            }
          />
        </div>
      </div>
      {tags.some(
        (e) => e.key === 'Special Message' && e.value === 'Empty Calendar'
      ) &&
        user.country.alpha2.toUpperCase() === 'NL' && (
          <Dialog
            className='absolute z-50 justify-center'
            open={tags.some(
              (e) => e.key === 'Special Message' && e.value === 'Empty Calendar'
            )}
            handleClose={toHome}
            render={
              <RegisterFollowUp
                text={t('feedback.special_message_on_empty_calendar')}
                textBtn={t('register.attendee.continue')}
                handleNext={toHome}
              />
            }
          />
        )}
    </div>
  );
}

CalendarScreen.propTypes = {
  tags: PropTypes.array,
  user: PropTypes.object,
  pathway: PropTypes.any,
  last_trainrg: PropTypes.any,
  last_event: PropTypes.any,
  localeReducerCalendar: PropTypes.any,
  localeReducerNotificationLanguage: PropTypes.any,
  localeReducerNotificationAcceptLanguage: PropTypes.any,
  initial_tags: PropTypes.any,
};

export default connect(mapStateToProps)(CalendarScreen);
