export const ENGLISH = 'en';
export const GERMAN = 'de';
export const DUTCH = 'nl';
export const GERMAN_AT = 'at';
export const FRENCH_SWISS = 'fr_ch';
export const GERMAN_SWISS = 'de_ch';
export const ITALIAN_SWISS = 'it_ch';
export const ENGLISH_GB = 'en_gb';
export const SPANISH = 'es';
export const FINNISH = 'fi';
export const ENGLISH_SOUTHAFRICA = 'za';
export const ZULU_SOUTHAFRICA = 'zu_ZA';
export const SWEDISH = 'se';
export const DANISH = 'dk';
export const POLISH = 'pl';
export const PORTUGUESE = 'pt';
export const GREEK = 'gr';
export const HEBREW = 'il';
export const NORWEGIAN = 'no';

export const ALL_LANGUAGES = [
  {
    name: 'English',
    code: ENGLISH,
    notificationLanguage: 'en',
    notificationAcceptLanguage: 'en-US',
    notificationCalendar: 'en',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Nederlands (Nederland)',
    code: DUTCH,
    notificationLanguage: 'nl',
    notificationAcceptLanguage: 'nl-NL',
    notificationCalendar: 'nl',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Deutsch (Deutschland)',
    code: GERMAN,
    notificationLanguage: 'de',
    notificationAcceptLanguage: 'de-DE',
    notificationCalendar: 'de',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Deutsch (Schweiz)',
    code: GERMAN_SWISS,
    notificationLanguage: 'de_ch',
    notificationAcceptLanguage: 'de-CH',
    notificationCalendar: 'de',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Italiano (Svizzera)',
    code: ITALIAN_SWISS,
    notificationLanguage: 'it_ch',
    notificationAcceptLanguage: 'it-CH',
    notificationCalendar: 'it',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Français (Suisse)',
    code: FRENCH_SWISS,
    notificationLanguage: 'fr_ch',
    notificationAcceptLanguage: 'fr-CH',
    notificationCalendar: 'fr',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Deutsch (Österreich)',
    code: GERMAN_AT,
    notificationLanguage: 'de_at',
    notificationAcceptLanguage: 'de-AT',
    notificationCalendar: 'de',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'English (UK & Ireland)',
    code: ENGLISH_GB,
    notificationLanguage: 'en_gb',
    notificationAcceptLanguage: 'en-GB',
    notificationCalendar: 'gb',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Español (España)',
    code: SPANISH,
    notificationLanguage: 'es',
    notificationAcceptLanguage: 'es-ES',
    notificationCalendar: 'es',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Suomi (Suomi)',
    code: FINNISH,
    notificationLanguage: 'fi',
    notificationAcceptLanguage: 'fi-FI',
    notificationCalendar: 'fi',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'English (South Africa)',
    code: ENGLISH_SOUTHAFRICA,
    notificationLanguage: 'za',
    notificationAcceptLanguage: 'en-ZA',
    notificationCalendar: 'za',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Zulu (South Africa)',
    code: ZULU_SOUTHAFRICA,
    notificationLanguage: 'zu-ZA',
    notificationAcceptLanguage: 'zu-ZA',
    notificationCalendar: 'zu',
    displayInProd: false,
    displayInTMP: true,
  },
  {
    name: 'Svenska (Sverige)',
    code: SWEDISH,
    notificationLanguage: 'se',
    notificationAcceptLanguage: 'sv-SE',
    notificationCalendar: 'se',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Dansk (Danmark)',
    code: DANISH,
    notificationLanguage: 'dk',
    notificationAcceptLanguage: 'da-DK',
    notificationCalendar: 'dk',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Polska',
    code: POLISH,
    notificationLanguage: 'pl',
    notificationAcceptLanguage: 'pl-PL',
    notificationCalendar: 'pl',
    displayInProd: false,
    displayInTMP: true,
  },
  {
    name: 'Português',
    code: PORTUGUESE,
    notificationLanguage: 'pt',
    notificationAcceptLanguage: 'pt-PT',
    notificationCalendar: 'pt',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Ελληνικά (Ελλάδα)',
    code: GREEK,
    notificationLanguage: 'gr',
    notificationAcceptLanguage: 'el-GR',
    notificationCalendar: 'gr',
    displayInProd: true,
    displayInTMP: true,
  },
  {
    name: 'Hebrew',
    code: HEBREW,
    notificationLanguage: 'il',
    notificationAcceptLanguage: 'he-IL',
    notificationCalendar: 'il',
    displayInProd: true,
    displayInTMP: true,
    dir: 'rtl',
  },
  {
    name: 'Norwegian',
    code: NORWEGIAN,
    notificationLanguage: 'no',
    notificationAcceptLanguage: 'nn-NO',
    notificationCalendar: 'no',
    displayInProd: true,
    displayInTMP: true,
  },
];

export const LANGUAGES_BY_COUNTRY = {
  DE: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Deutsch (Deutschland)',
      code: GERMAN,
      notificationLanguage: 'de',
      notificationAcceptLanguage: 'de-DE',
      notificationCalendar: 'de',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  NL: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Nederlands (Nederland)',
      code: DUTCH,
      notificationLanguage: 'nl',
      notificationAcceptLanguage: 'nl-NL',
      notificationCalendar: 'nl',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  CH: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Deutsch (Schweiz)',
      code: GERMAN_SWISS,
      notificationLanguage: 'de_ch',
      notificationAcceptLanguage: 'de-CH',
      notificationCalendar: 'de',
      displayInProd: true,
      displayInTMP: true,
    },
    {
      name: 'Italiano (Svizzera)',
      code: ITALIAN_SWISS,
      notificationLanguage: 'it_ch',
      notificationAcceptLanguage: 'it-CH',
      notificationCalendar: 'it',
      displayInProd: true,
      displayInTMP: true,
    },
    {
      name: 'Français (Suisse)',
      code: FRENCH_SWISS,
      notificationLanguage: 'fr_ch',
      notificationAcceptLanguage: 'fr-CH',
      notificationCalendar: 'fr',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  AT: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Deutsch (Österreich)',
      code: GERMAN_AT,
      notificationLanguage: 'de_at',
      notificationAcceptLanguage: 'de-AT',
      notificationCalendar: 'de',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  GB: [
    {
      name: 'English (UK & Ireland)',
      code: ENGLISH_GB,
      notificationLanguage: 'en_gb',
      notificationAcceptLanguage: 'en-GB',
      notificationCalendar: 'gb',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  ES: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Español (España)',
      code: SPANISH,
      notificationLanguage: 'es',
      notificationAcceptLanguage: 'es-ES',
      notificationCalendar: 'es',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  FI: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Suomi (Suomi)',
      code: FINNISH,
      notificationLanguage: 'fi',
      notificationAcceptLanguage: 'fi-FI',
      notificationCalendar: 'fi',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  ZA: [
    {
      name: 'English (South Africa)',
      code: ENGLISH_SOUTHAFRICA,
      notificationLanguage: 'za',
      notificationAcceptLanguage: 'en-ZA',
      notificationCalendar: 'za',
      displayInProd: true,
      displayInTMP: true,
    },
    {
      name: 'Zulu (South Africa)',
      code: ZULU_SOUTHAFRICA,
      notificationLanguage: 'zu-ZA',
      notificationAcceptLanguage: 'zu-ZA',
      notificationCalendar: 'zu',
      displayInProd: false,
      displayInTMP: true,
    },
  ],
  SE: [
    {
      name: 'Svenska (Sverige)',
      code: SWEDISH,
      notificationLanguage: 'se',
      notificationAcceptLanguage: 'sv-SE',
      notificationCalendar: 'se',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  DK: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Dansk (Danmark)',
      code: DANISH,
      notificationLanguage: 'dk',
      notificationAcceptLanguage: 'da-DK',
      notificationCalendar: 'dk',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  PL: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Polska',
      code: POLISH,
      notificationLanguage: 'pl',
      notificationAcceptLanguage: 'pl-PL',
      notificationCalendar: 'pl',
      displayInProd: false,
      displayInTMP: true,
    },
  ],
  PT: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Português',
      code: PORTUGUESE,
      notificationLanguage: 'pt',
      notificationAcceptLanguage: 'pt-PT',
      notificationCalendar: 'pt',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  GR: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Ελληνικά',
      code: GREEK,
      notificationLanguage: 'gr',
      notificationAcceptLanguage: 'el-GR',
      notificationCalendar: 'gr',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  IL: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Hebrew',
      code: HEBREW,
      notificationLanguage: 'il',
      notificationAcceptLanguage: 'he-IL',
      notificationCalendar: 'il',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
  NO: [
    {
      name: 'English',
      code: ENGLISH,
      notificationLanguage: 'en',
      notificationAcceptLanguage: 'en-US',
      notificationCalendar: 'en',
      displayInProd: false,
      displayInTMP: true,
    },
    {
      name: 'Norwegian',
      code: NORWEGIAN,
      notificationLanguage: 'no',
      notificationAcceptLanguage: 'nn-NO',
      notificationCalendar: 'no',
      displayInProd: true,
      displayInTMP: true,
    },
  ],
};
