/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useState, useEffect, Fragment } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import PropTypes, { object } from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Media from 'react-media';
import { TrashSVG } from '../../src/assets/trash.jsx';
import { DisabledTrashSVG } from '../../src/assets/disabled-trash.jsx';
// import Table from '../components/table/Table';
import User from '../schema/User';
// import TableHead from '../components/table/TableHead';
import Button from '../components/button/Button';
// import MyEventsTableBody from '../components/my-events/MyEventsTableBody';
import Link from '../components/link/Link';
import { LoadingFullScreenCentered } from '../components/loading-indicator/Loading';
import EventModal from '../components/calendar/EventModal';
import CancelRezervationModal from '../components/calendar/CancelRezervationModal';
import Error from '../components/error/Error';
import NoEventsFound from '../components/my-events/NoEventsFound';
import { TimeWindow } from '../helpers/CalendarEventWindowTime';

const COLUMNS_TO_DISPLAY = [
  'title',
  'date',
  'time',
  'location',
  'details',
  'deleteHead',
];

const COLUMNS_TO_DISPLAY_MOBILE = [
  'title',
  'location',
  'details',
  'deleteHead',
];

const mapStateToProps = (state) => {
  return {
    locale: state.localeReducer.locale,
    user: state.userReducer.user,
  };
};

// eslint-disable-next-line no-unused-vars
function MyEventsScreen({ locale, user }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openCR, setOpenCR] = useState(false);

  const [eventData, setEventData] = useState([]);

  const { error, loading, data } = useQuery(User.GET_USER_EVENTS);

  const [events, setEvents] = useState([]);

  const timeFrameWindow = TimeWindow[user.country.alpha2] || [
    { myEventTimeBeforeCancel: 0 },
  ];
  var timeNow = Math.round(new Date().getTime() / 1000);

  useEffect(() => {
    if (data) {
      setEvents(
        data.SCHviewer?.attendeeTo
          .map((event) => {
            if (event.__typename != 'RemovedNode') {
              const copyObject = Object.assign({}, event.event);

              copyObject.eventStored = event.event;
              copyObject.atendeeEventId = event.id;
              copyObject.eventId = event.event.id;
              // copyObject.date = moment.unix(event.event.start).format('MMM Do')
              copyObject.date = moment.unix(event.event.start).format('MMM DD');
              copyObject.time = moment.unix(event.event.start).format('HH:mm');
              copyObject.start = new Date(event.event.start * 1000);
              copyObject.description = event.event.description;

              const allowToUnregister =
                new Date(event.event.start * 1000).getTime() / 1000 - timeNow <
                timeFrameWindow[0].myEventTimeBeforeCancel
                  ? true
                  : false;
              copyObject.allowToUnregister = allowToUnregister;

              copyObject.end = new Date(event.event.end * 1000);
              copyObject.userStorage = user;
              copyObject.location =
                copyObject.isOnline && typeof copyObject.location === 'string'
                  ? copyObject.location?.split(',').pop()
                  : copyObject.location;

              copyObject.details = (
                <Link
                  onClick={() => handleOpen(copyObject)}
                  className='uppercase'
                >
                  {/* {t('my_events.details_button')} */}
                  {copyObject.location}
                </Link>
              );
              copyObject.deleteHead = (
                <Media
                  queries={{
                    small: '(max-width: 768px)',
                    large: '(min-width: 769px)',
                  }}
                >
                  {(matches) => (
                    <Fragment>
                      {matches.small && (
                        <div
                          disabled={allowToUnregister}
                          className='trashButton'
                          onClick={() =>
                            allowToUnregister ? null : handleOpenCR(copyObject)
                          }
                        >
                          {allowToUnregister === false && <TrashSVG />}
                          {allowToUnregister === true && <DisabledTrashSVG />}
                        </div>
                      )}
                      {matches.large && (
                        <Button
                          className='px-6'
                          disabled={allowToUnregister}
                          onClick={() =>
                            allowToUnregister ? null : handleOpenCR(copyObject)
                          }
                        >
                          {t('my_events.header_columns.deleteBody')}
                        </Button>
                      )}
                    </Fragment>
                  )}
                </Media>
              );
              return copyObject;
            } else {
              return [];
            }
          })
          .filter(
            (notUndefined) =>
              typeof notUndefined === 'object' &&
              typeof notUndefined?.start !== 'undefined'
          )
          .sort((a, b) => b.eventStored?.start - a.eventStored?.start)
      );
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
    setOpenCR(false);
  };

  const handleOpen = (event) => {
    setOpen(true);
    setEventData(event);
  };

  const handleOpenCR = (event) => {
    setOpenCR(true);
    setEventData(event);
  };

  if (loading) {
    return <LoadingFullScreenCentered />;
  }

  if (error) {
    return <Error>{t('something_went_wrong')}</Error>;
  }

  if (!data.SCHviewer) {
    return <NoEventsFound className='mt-5' />;
  }

  if (!events.length) {
    return <NoEventsFound className='mt-5' />;
  }

  if (events.filter(String).length === 0) {
    return <NoEventsFound className='mt-5' />;
  }

  return (
    <div className=''>
      {eventData.start && (
        <EventModal
          open={open}
          handleClose={handleClose}
          eventData={eventData}
        />
      )}
      {eventData.start && (
        <CancelRezervationModal
          open={openCR}
          handleClose={handleClose}
          eventData={eventData}
        />
      )}
      {/* <div className='hidden mb-10 lg:flex'>
        <div className='w-3/12 bg-cover bg-my-events-left' />
        <div className='w-3/12'>
          <div className='grid w-full grid-cols-2 grid-rows-2'>
            <div className='h-28 bg-sky-blue' />
            <div className='h-28 bg-cobalt' />
            <div className='h-28 bg-medium-blue' />
            <div className='h-28 bg-light-blue' />
          </div>
        </div>
        <div className='w-3/12 bg-primary' />
        <div className='w-3/12 bg-cover bg-my-events-right' />
      </div> */}
      <div>
        <Media
          queries={{
            small: '(max-width: 768px)',
            large: '(min-width: 769px)',
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.small && (
                <div className='flex justify-center shadow-3xl '>
                  <div className='w-full space-y-6 lg:p-18 xs:p-6'>
                    <div className='text-5xl text-electric-blue'>
                      {t('navigation.my_events')}
                    </div>
                    <hr
                      className='z-0 text-center rounded-full'
                      style={{
                        borderTop: '2px solid rgba(136,139,141, 0.3)',
                      }}
                    />

                    {events.map((row, index) => {
                      return (
                        <div key={index} className='space-y-6'>
                          <div className='space-y-2'>
                            <div className='text-lg text-dark-grey-body '>
                              <span className='font-bold'>
                                {t('my_events.header_columns.name')}
                                {': '}
                              </span>
                              {row.title}
                            </div>
                            <div className='text-lg text-dark-grey-body'>
                              <span className='font-bold'>
                                {t('my_events.header_columns.date')}
                                {': '}
                              </span>
                              {moment(row.start).format('Do MMMM, HH:mm')}
                            </div>
                            <div className='text-lg text-dark-grey-body'>
                              <span className='font-bold'>
                                {t('my_events.header_columns.location')}
                                {': '}
                              </span>
                              {row.title}
                            </div>
                            {/* <div className='text-lg text-dark-grey-body'>
                              <span className='font-bold'>
                                {t('my_events.header_columns.description')}
                                {': '}
                              </span>
                              {row.description}
                            </div> */}
                            <Button
                              className='px-4 rounded-full text-dark-grey-body'
                              disabled={row.allowToUnregister}
                              onClick={() =>
                                row.allowToUnregister ? null : handleOpenCR(row)
                              }
                            >
                              {t('my_events.header_columns.deleteBody')}
                            </Button>
                          </div>

                          {index !== events.length - 1 && (
                            <hr
                              className='z-0 text-center rounded-full '
                              style={{
                                borderTop: '2px solid #1320ff',
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>

                // <div>
                //   <MyEventsTableBody
                //     columnsToShow={COLUMNS_TO_DISPLAY_MOBILE}
                //     header={[
                //       t('my_events.header_columns.name'),
                //       t('my_events.header_columns.location'),
                //       t('my_events.header_columns.details'),
                //       t('my_events.header_columns.deleteHead'),
                //     ]}
                //     data={events}
                //   />
                // </div>
              )}
              {matches.large && (
                <div className=''>
                  <div className='flex justify-center shadow-3xl'>
                    <div className='w-2/3 space-y-6 lg:p-18 xs:p-6'>
                      <div className='text-5xl text-electric-blue'>
                        {t('navigation.my_events')}
                      </div>
                      <hr
                        className='z-0 text-center rounded-full'
                        style={{
                          borderTop: '2px solid rgba(136,139,141, 0.3)',
                        }}
                      />

                      {events.map((row, index) => {
                        return (
                          <div key={index} className='space-y-6'>
                            <div className='space-y-2'>
                              <div className='text-lg text-dark-grey-body '>
                                <span className='font-bold'>
                                  {t('my_events.header_columns.name')}
                                  {': '}
                                </span>
                                {row.title}
                              </div>
                              <div className='text-lg text-dark-grey-body'>
                                <span className='font-bold'>
                                  {t('my_events.header_columns.date')}
                                  {': '}
                                </span>
                                {moment(row.start).format('Do MMMM, HH:mm')}
                              </div>
                              <div className='text-lg text-dark-grey-body'>
                                <span className='font-bold'>
                                  {t('my_events.header_columns.location')}
                                  {': '}
                                </span>
                                {row.location}
                              </div>
                              {/* <div className='text-lg text-dark-grey-body'>
                                <span className='font-bold'>
                                  {t('my_events.header_columns.description')}
                                  {': '}
                                </span>
                                {row.description}
                              </div> */}
                              <Button
                                className='px-4 rounded-full text-dark-grey-body'
                                disabled={row.allowToUnregister}
                                onClick={() =>
                                  row.allowToUnregister
                                    ? null
                                    : handleOpenCR(row)
                                }
                              >
                                {t('my_events.header_columns.deleteBody')}
                              </Button>
                            </div>

                            {index !== events.length - 1 && (
                              <hr
                                className='z-0 text-center rounded-full '
                                style={{
                                  borderTop: '2px solid #1320ff',
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                      {/* <Table
                      tableHead={
                        <TableHead
                          data={[
                            t('my_events.header_columns.name'),
                            t('my_events.header_columns.date'),
                            t('my_events.header_columns.time'),
                            t('my_events.header_columns.location'),
                            t('my_events.header_columns.details'),
                            t('my_events.header_columns.deleteHead'),
                          ]}
                        />
                      }
                      tableBody={
                        <MyEventsTableBody
                          columnsToShow={COLUMNS_TO_DISPLAY}
                          header={[
                            t('my_events.header_columns.name'),
                            t('my_events.header_columns.date'),
                            t('my_events.header_columns.time'),
                            t('my_events.header_columns.location'),
                            t('my_events.header_columns.details'),
                            t('my_events.header_columns.deleteHead'),
                          ]}
                          data={events}
                        />
                      }
                    /> */}
                    </div>
                    <div className='hidden w-1/3 bg-no-repeat bg-cover h-login bg-login bg-30 lg:block'></div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </Media>
      </div>
    </div>
  );
}

MyEventsScreen.propTypes = {
  locale: PropTypes.string,
  user: PropTypes.any,
};
export default connect(mapStateToProps)(MyEventsScreen);
