import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Layout from '../../forms/Layout';
import Label from '../../label/Label';
import routes from '../../../helpers/routes';
import Text from '../../forms/inputs/Text';
import Button from '../../button/Button';
import { addTags } from '../../../actions/tags';
//import { store } from '../../../store/redux';
//import { DE_ONLY_PUMP_QUESTION } from '../../../constants/form';
//import ItemList from '../../item-list/ItemList';

const mapDispatchToProps = (dispatch) => ({
  dispatchTags: (tags) => dispatch(addTags(tags)),
});

export function SingleInputSelection({
  handleBack,
  stepName,
  stepDescription,
  dispatchTags,
  history,
}) {
  const [eventCode, setEventCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  //const { data } = props;

  /*const alpha2 = store.getState().userReducer.user
    ? store.getState().userReducer.user
    : { country: { alpha2: 'FAIL' } };

  const [formData, setFormData] = useState({
    prod_type: data.prod_type || '',
    de_pump_only_question: data.de_pump_only_question || '',
    de_cgm_only_question: data.de_cgm_only_question || '',
    uk_pronouns_qestion: data.uk_pronouns_qestion || '',
    uk_hospital: data.uk_hospital || '',
    uk_healthcare_professional: data.uk_healthcare_professional || '',
  });*/

  const handleSubmit = () => {
    setLoading(true);

    const finalTag = [
      {
        key: 'Event Code',
        value: eventCode ? eventCode : 'WORKSHOP',
      },
    ];

    dispatchTags(finalTag);
    history.push(routes.calendar);
  };

  return (
    <Layout
      handleBack={handleBack}
      heading={
        <div className='mb-5'>
          <Label
            label={t(stepName)}
            variant='h1'
            className='text-electric-blue uppercase font-bold text-2xl leading-tight'
          />
          <Label
            label={t(stepDescription)}
            className='text-base leading-tight'
          />
        </div>
      }
    >
      <ValidatorForm onSubmit={(e) => e.preventDefault()} className='p-8'>
        <Text
          placeholder={t('cvent.technical.workshop.event_code')}
          name='eventCode'
          disabled={loading}
          value={eventCode}
          onChange={(e) => setEventCode(e)}
        />
        {/*alpha2.country?.alpha2 === 'DE' && (
          <div>
            <Text
              name='de_pump_only_question'
              value={formData.de_pump_only_question}
              placeholder={`${t(
                'cvent.technical.form.product_familiarity.label'
              )} *`}
              select
              SelectProps={{
                native: true,
              }}
              onChange={(e) => {
                setFormData({ ...formData, de_pump_only_question: e });
              }}
            >
              <option value=''>
                {t('cvent.technical.form.de_pump_exp.label')} *
              </option>
              <ItemList items={DE_ONLY_PUMP_QUESTION} translate={true} />
            </Text>
          </div>
        )*/}
        {/* <Button
          // className='mt-5'
          className='rounded-full text-dark-grey-body px-4 mt-4'
          type='submit'
          disabled={loading}
          onClick={handleSubmit}
        >
          {t('global.next')}
        </Button> */}
        <div className='flex justify-end mt-4'>
          <Button
            type='submit'
            disabled={loading}
            onClick={handleSubmit}
            className='rounded-full text-dark-grey-body px-4 '
          >
            {t('global.next')}
          </Button>
        </div>
      </ValidatorForm>
    </Layout>
  );
}

SingleInputSelection.propTypes = {
  handleBack: PropTypes.func,
  stepName: PropTypes.string,
  stepDescription: PropTypes.string,
  dispatchTags: PropTypes.func,
  history: PropTypes.object,
  data: PropTypes.object,
};

SingleInputSelection.defaultProps = {
  data: {},
};
export default connect(null, mapDispatchToProps)(SingleInputSelection);
