export const ENTRY_LEVEL = 0;
export const PRODUCT_AND_THERAPY = 1;
export const SUMMARY = 2;

export const FLOW = {
  'cvent.steps.cvent_entry_level': ENTRY_LEVEL,
  'cvent.steps.cvent_product_and_therapy': PRODUCT_AND_THERAPY,
  Summary: SUMMARY,
};

export const COUNTRIES = {
  NETHERLANDS: 'nl',
  GERMANY: 'de',
  SWITZERLAND: 'ch',
  AUSTRIA: 'at',
  UNITED_KINGDOM: 'gb',
  SPAIN: 'es',
  FINLAND: 'fi',
  SOUTH_AFRICA: 'za',
  SWEDEN: 'se',
  DENMARK:'dk',
  POLAND:'pl',
  PORTUGAL:'pt',
  GREECE:'gr',
  ISRAEL:'il',
  NORWAY:'no'
};

export const SUPPORTED_COUNTRIES = {
  [COUNTRIES.NETHERLANDS]: COUNTRIES.NETHERLANDS,
  [COUNTRIES.GERMANY]: COUNTRIES.GERMANY,
  [COUNTRIES.SWITZERLAND]: COUNTRIES.SWITZERLAND,
  [COUNTRIES.AUSTRIA]: COUNTRIES.AUSTRIA,
  [COUNTRIES.UNITED_KINGDOM]: COUNTRIES.UNITED_KINGDOM,
  [COUNTRIES.SPAIN]: COUNTRIES.SPAIN,
  [COUNTRIES.FINLAND]: COUNTRIES.FINLAND,
  [COUNTRIES.SOUTH_AFRICA]: COUNTRIES.SOUTH_AFRICA,
  [COUNTRIES.SWEDEN]: COUNTRIES.SWEDEN,
  [COUNTRIES.DENMARK]: COUNTRIES.DENMARK,
  [COUNTRIES.POLAND]: COUNTRIES.POLAND,
  [COUNTRIES.PORTUGAL]: COUNTRIES.PORTUGAL,
  [COUNTRIES.GREECE]: COUNTRIES.GREECE,
  [COUNTRIES.ISRAEL]: COUNTRIES.ISRAEL,
  [COUNTRIES.NORWAY]: COUNTRIES.NORWAY,
};
