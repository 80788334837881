export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '2.1',
        title: 'cvent.cards.sixth_step.title',
        label: 'cvent.cards.sixth_step.button',
        image: '/workflow/workshop.jpg',
        description: 'cvent.cards.sixth_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '2.1',
        title: 'cvent.cards.seventh_step.title',
        label: 'cvent.cards.seventh_step.button',
        image: '/workflow/at_pen.jpg',
        description: 'cvent.cards.seventh_step.description',
        disabled: false,
      },
      // {
      //   from: '1',
      //   to: '9',
      //   title: '',
      //   label: '',
      //   image: '',
      //   description: '',
      //   blank: true,
      //   disabled: true,
      // },
      {
        from: '1',
        // to: '9',
        title: 'cvent.cards.fifth_step.title',
        label: 'cvent.cards.fifth_step.button',
        image: '/workflow/guardian.jpg',
        description: 'cvent.cards.fifth_step.description',
        disabled: false,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Fota',
          },
        ],
      },
      {
        from: '1',
        to: '9',
        title: 'cvent.cards.fourth_step.title',
        label: 'cvent.cards.fourth_step.button',
        image: '/workflow/special-training.jpg',
        description: 'cvent.cards.fourth_step.description',
        disabled: false,
      },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '3',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '2.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2.1',
        to: 'Summary',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2.1',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '3',
    centered: true,
    stepName: 'cvent.steps.nl.form_title',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: {},
    componentType: 'FormSelection',
    options: [
      {
        from: '2',
        to: '5',
        customFlow: '10',
        title: 'cvent.steps.nl.form_completed',
        label: 'cvent.steps.nl.form_completed',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.nl.entry_level_title',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '5',
        to: '6',
        title: 'cvent.steps.nl.pump_only',
        label: '',
      },
      {
        from: '5',
        to: 'customOkayQuestionPumpAndCGM',
        title: 'cvent.steps.nl.pump_and_cgm',
        label: '',
      },
      {
        from: '5',
        to: '6',
        title: 'cvent.steps.nl.cgm_for_pump',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'customOkayQuestionPumpAndCGM',
    centered: true,
    stepName: 'cvent.steps.nl.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'customOkayQuestionPumpAndCGM',
        to: 'customOkayQuestionPumpEXP640Path', //7
        title: '640g',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpAndCGM',
        to: 'customOkayQuestionPumpEXP780Path', //7
        title: '780g',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6',
    centered: true,
    stepName: 'cvent.steps.nl.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '6',
        to: '7',
        title: '640g',
        label: '',
      },
      {
        from: '6',
        to: '7',
        title: '780g',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'customOkayQuestionPumpEXP640Path',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'customOkayQuestionPumpEXP640Path',
        to: 'customOkayQuestionPumpEXP640PathSensEXP',
        title: 'cvent.steps.nl.veo',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP640Path',
        to: '8',
        title: '640g',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP640Path',
        to: '8',
        title: '670g',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP640Path',
        to: 'customOkayQuestionPumpEXP640PathSensEXP',
        title: 'cvent.steps.nl.other',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP640Path',
        to: 'customOkayQuestionPumpEXP640PathSensEXP', //8
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'customOkayQuestionPumpEXP780Path', //7
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'customOkayQuestionPumpEXP780Path',
        to: 'customOkayQuestionPumpEXP780PathSensEXP', //8
        title: 'cvent.steps.nl.veo',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP780Path',
        to: 'customOkayQuestionPumpEXP780PathSensEXP',
        title: '640g',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP780Path',
        to: '8',
        title: '670g',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP780Path',
        to: 'customOkayQuestionPumpEXP780PathSensEXP',
        title: 'cvent.steps.nl.other',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP780Path', //7
        to: 'customOkayQuestionPumpEXP780PathSensEXP', //8
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '7',
        to: '8',
        title: 'cvent.steps.nl.veo',
        label: '',
      },
      {
        from: '7',
        to: '8',
        title: '640g',
        label: '',
      },
      {
        from: '7',
        to: '8',
        title: '670g',
        label: '',
      },
      {
        from: '7',
        to: '8',
        title: 'cvent.steps.nl.other',
        label: '',
      },
      {
        from: '7',
        to: '8',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'customOkayQuestionPumpEXP640PathSensEXP',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy_sensor',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'customOkayQuestionPumpEXP640PathSensEXP',
        to: 'customOkayQuestion',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP640PathSensEXP',
        to: 'customOkayQuestion',
        title: 'cvent.steps.nl.experience_with_mdt',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP640PathSensEXP',
        to: 'customOkayQuestion',
        title: 'cvent.steps.nl.experience_with_other',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'customOkayQuestionPumpEXP780PathSensEXP', //8
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy_sensor',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'customOkayQuestionPumpEXP780PathSensEXP',
        to: 'customOkayQuestion',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP780PathSensEXP',
        to: 'customOkayQuestion',
        title: 'cvent.steps.nl.experience_with_mdt',
        label: '',
      },
      {
        from: 'customOkayQuestionPumpEXP780PathSensEXP', //8
        to: 'customOkayQuestion',
        title: 'cvent.steps.nl.experience_with_other',
        label: '',
      },
    ],
  },
  {
    stepNumber: '8',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy_sensor',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '8',
        to: 'Summary',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
      {
        from: '8',
        to: 'Summary',
        title: 'cvent.steps.nl.experience_with_mdt',
        label: '',
      },
      {
        from: '8',
        to: 'Summary',
        title: 'cvent.steps.nl.experience_with_other',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'customOkayQuestion',
    centered: true,
    stepName: 'cvent.steps.cvent_check_do_not_close_warning_flow',
    stepDescription: 'cvent.steps.cvent_check_do_not_close_warning_flow',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'customOkayQuestion',
        to: 'Summary',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
    ],
  },
  {
    stepNumber: '9',
    stepName: 'Special Technical Instructions',
    stepDescription: 'Form Technical instruction',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'ThirdForm',
    options: [
      {
        from: '1',
        to: '1',
        title: 'OK',
        label: '',
      },
    ],
  },
  {
    stepNumber: '10',
    centered: true,
    stepName: 'cvent.steps.nl.entry_level_title',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '10',
        to: '11',
        title: 'cvent.steps.nl.pump_only_2',
        label: '',
      },
      {
        from: '10',
        to: '11.1',
        title: 'cvent.steps.nl.pump_and_cgm_2',
        label: '',
      },
      {
        from: '10',
        to: '11.2',
        title: 'cvent.steps.nl.cgm_for_pump_2',
        label: '',
      },
    ],
  },
  {
    stepNumber: '11',
    centered: true,
    stepName: 'cvent.steps.nl.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '11',
        to: 'Summary',
        title: '740g',
        label: '',
      },
      {
        from: '11',
        to: 'Summary',
        title: '780g',
        label: '',
      },
    ],
  },
  {
    stepNumber: '11.1',
    centered: true,
    stepName: 'cvent.steps.nl.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '11.1',
        to: 'Summary',
        title: '740g_cgm_pump',
        label: '',
      },
      {
        from: '11.1',
        to: 'Summary',
        title: '780g_cgm_pump',
        label: '',
      },
    ],
  },
  {
    stepNumber: '11.2',
    centered: true,
    stepName: 'cvent.steps.nl.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '11.2',
        to: 'Summary',
        title: '740g_cgm',
        label: '',
      },
      {
        from: '11.2',
        to: 'Summary',
        title: '780g_cgm',
        label: '',
      },
    ],
  },
  {
    stepNumber: '12',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '12',
        to: 'Summary',
        title: 'cvent.steps.nl.veo',
        label: '',
      },
      {
        from: '12',
        to: 'Summary',
        title: '640g',
        label: '',
      },
      {
        from: '12',
        to: 'Summary',
        title: '670g',
        label: '',
      },
      {
        from: '12',
        to: 'Summary',
        title: 'cvent.steps.nl.other',
        label: '',
      },
      {
        from: '12',
        to: 'Summary',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
    ],
  },
  {
    stepNumber: '12.1',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '12.1',
        to: 'Summary',
        title: 'cvent.steps.nl.veo',
        label: '',
      },
      {
        from: '12.1',
        to: 'Summary',
        title: '640g',
        label: '',
      },
      {
        from: '12.1',
        to: 'Summary',
        title: 'cvent.steps.nl.other',
        label: '',
      },
      {
        from: '12.1',
        to: 'Summary',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
    ],
  },
  {
    stepNumber: '12.2',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '12.2',
        to: '13',
        title: 'cvent.steps.nl.veo',
        label: '',
      },
      {
        from: '12.2',
        to: '13',
        title: 'cvent.steps.nl.other',
        label: '',
      },
      {
        from: '12.2',
        to: '13',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
    ],
  },
  {
    stepNumber: '12.3',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '12.3',
        to: '13',
        title: 'cvent.steps.nl.veo',
        label: '',
      },
      {
        from: '12.3',
        to: '13',
        title: '640g',
        label: '',
      },
      {
        from: '12.3',
        to: '13',
        title: '670g',
        label: '',
      },
      {
        from: '12.3',
        to: '13',
        title: 'cvent.steps.nl.other',
        label: '',
      },
      {
        from: '12.3',
        to: '13',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
    ],
  },
  {
    stepNumber: '13',
    centered: true,
    stepName: 'cvent.steps.nl.select_therapy_sensor',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '13',
        to: 'Summary',
        title: 'cvent.steps.nl.no_experience',
        label: '',
      },
      {
        from: '13',
        to: 'Summary',
        title: 'cvent.steps.nl.experience_with_mdt',
        label: '',
      },
      {
        from: '13',
        to: 'Summary',
        title: 'cvent.steps.nl.experience_with_other',
        label: '',
      },
    ],
  },
];
